import React from 'react'
import styled from 'styled-components'
import prims from '../components/Primitives'
import PageLayout from '../components/logic/PageLayout'
import PageWrapper from '../components/style/PageWrapper'
import H2 from '../components/style/H2'
import PMedium from '../components/style/PMedium'
import Headshot from '../components/img/headshot.jpg'
import H3 from '../components/style/H3'
import pdfIcon from '../components/img/icons/pdf.svg'
import H4 from '../components/style/H4'
import HistoryItem from '../components/logic/HistoryItem'
import SkillsSuperlistItem from '../components/logic/SkillsSuperlistItem'
//import CaretIcon from '../components/img/icons/caret.svg'

const Bio = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  @media (max-width: 450px) {
    display: block;
  }
`
const HalfColumn = styled.div`
  width: 39.5rem;

  &:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 600px) {
    width: calc(100vw - 4rem);
  }
`
const Avatar = styled.img`
  align-self: center;
  height: 16rem;
  width: 16rem;
  
  border: 1rem solid ${prims.colors.blue.white.opaque};
  border-radius: 8rem;
  z-index: 1;
`
const ResumeBackground = styled.div`
  align-self: center;
  display: flex;
  height: 98rem;
  justify-content: center;
  margin: -7rem 0 0 0;
  padding: 7rem 0 0 0;
  width: 100vw;
  //z-index: -1;

  background: radial-gradient(circle at top, ${prims.colors.blue.lightest}, ${prims.colors.blue.lighter});

  @media (max-width: 1200px) {
    height: auto;
    padding-bottom: 1rem;
    margin-bottom: -14rem;
  }
`
const Resume = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  width: 80rem;

  @media (max-width: 1200px) {
    width: calc(100vw - 4rem);
    max-width: 80rem;
  }
`
const EducationCard = styled.div`
  margin: 0 1rem 0 -1rem;
  padding: 1rem;
  width: 40.5rem;

  background: ${prims.colors.blue.white.opaque};
  
  border-radius: 2px;
  box-shadow: ${prims.glow.m} ${prims.colors.blue.dark.transparent_l}, ${prims.shadows.l} ${prims.colors.blue.dark.transparent_l};

  @media (max-width: 1200px) {
    margin: 0 -1rem 2rem -1rem;
    width: auto;
  }
`
const WorkCard = styled.div`
  margin: 0 -1rem 1rem 0;
  padding: 1rem;
  width: 40.5rem;

  background: ${prims.colors.blue.white.opaque};

  border-radius: 2px;
  box-shadow: ${prims.glow.m} ${prims.colors.blue.dark.transparent_l}, ${prims.shadows.l} ${prims.colors.blue.dark.transparent_l};
  
  @media (max-width: 1200px) {
    margin: 0 -1rem 2rem -1rem;
    width: auto;
  }
`
const SkillsCard = styled.div`
  margin: 0 -1rem 0 -1rem;
  padding: 1rem;
  width: 82rem;

  background: ${prims.colors.blue.white.opaque};
  
  border-radius: 2px;
  box-shadow: ${prims.glow.m} ${prims.colors.blue.dark.transparent_l}, ${prims.shadows.l} ${prims.colors.blue.dark.transparent_l};
`
const SkillsSuperlist = styled.ol`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  list-style-type: none;
`

const TriviaBackground = styled.div`
  align-self: center;
  display: flex;
  height: 52rem;
  justify-content: center;
  margin: -7rem 0 0 0;
  width: 100vw;
  z-index: -2;

  background: radial-gradient(circle at bottom, ${prims.colors.blue.dark.opaque}, ${prims.colors.blue.black.opaque});
`

// Background for trivia title
// background-image: linear-gradient(transparent 61%, ${prims.colors.blue.gray} 61%, ${prims.colors.blue.gray} 74%, transparent 74%);

const Trivia = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 25rem;
  width: 80rem;

  @media (max-width: 1200px) {
    width: 100vw;
  }
`

const TriviaTitle = styled.h5`
  height: 6rem;
  padding: 1.6rem 1rem 0;
  width: 41.5rem;

  color: ${prims.colors.blue.white.opaque};
  
  font-family: 'RucksackBold';
  font-size: ${prims.fontsize.l};
  line-height: 3rem;

  @media (max-width: 1200px) {
    width: 60vw;
    padding: 1.6rem 0 0;
  }
`

const TriviaItem = styled.div`
  display: flex;
  padding-top: 1rem;
  width: 53.5rem;

  @media (max-width: 1200px) {
    width: 100vw;
  }
`
const TriviaButton = styled.button`
  height: 4rem;
  width: 5rem;
  margin: 3rem ${props => props.previous ? '1rem;' : null} 0 ${props => props.next ? '1rem;' : null};

  background-color: transparent;
  background-size: 4rem 4rem;

  border: none;
  transform: ${props => props.next ? null : 'scaleX(-1);'};

  @media (max-width: 1200px) {
    margin: 3rem 0 0 0;
    width: 20vw;
  }
`
// TriviaButton background
// background-image: url(${CaretIcon});
const TriviaText = styled.p`
  padding: 0.85rem 0 .65rem 0;
  width: 39.5rem;

  color: ${prims.colors.blue.white.opaque};
  
  font-size: ${prims.fontsize.m};
  line-height: 3rem;

  @media (max-width: 1200px) {
    width: 60vw;
  }
`

export default () => (
  <PageLayout>
    <PageWrapper>
    	<H2>About</H2>
      <Bio>
        <HalfColumn>
          <PMedium>I am currently working as a UX Designer and Front-End Developer at Acando / CGI's Innovation Lab, in Gothenburg, Sweden.</PMedium>
          <PMedium>Before this, I received a Master's degree in Interaction Design at Chalmers University of Technology, and a Bachelor's degree in Computing Science in The Netherlands, where I've also worked as UX designer and front-end developer.</PMedium>
        </HalfColumn>
        <HalfColumn>
          <PMedium>I'm passionate about <em>shaping technology as a beautiful and empowering tool</em> for people to realise their own ambitions.</PMedium>
          <PMedium>I enjoy all parts of the design and development process, and in particular I like creating interactive prototypes to validate ideas or guide product development. Above all, I strive to keep learning and giving back through sharing and teaching.</PMedium>
        </HalfColumn>
      </Bio>
      <Avatar src={Headshot}/>
      <ResumeBackground>
        <Resume>
          <H3>Résumé <a href="/DanielRoeven_Resume.pdf" target="_blank" rel="noopener noreferrer">as PDF<img src={pdfIcon} alt=""/></a></H3>
          <EducationCard>
            <H4>Education</H4>
            <HistoryItem
              title="Interaction Design, MSc"
              time="2017-Present"
              description1="Chalmers University of Technology"
              description2="Gothenburg, Sweden"/>
            <HistoryItem
              title="Computing Science, BSc"
              time="2013-2017"
              description1="Radboud University"
              description2="Nijmegen, the Netherlands"/>
            <HistoryItem
              title="Industrial Design, BSc Minor"
              time="2016"
              description1="Eindhoven University of Technology"
              description2="Eindhoven, the Netherlands"/>
          </EducationCard>
          <WorkCard>
            <H4>Work Experience</H4>
            <HistoryItem
              title="UX Engineer at Acando / CGI’s Innovation Lab"
              time="Aug 2019 - Present"
              description1="I helped start up the Innovation Lab at Acando, where I’m currently employed as UX designer and front-end developer, creating POCs and MVPs for clients with emerging tech like AR and Voice UIs."/>
            <HistoryItem
              title="Master Thesis at the Techno Creatives"
              time="Jan 2019 - Jun 2019"
              description1="Designing and prototyping augmented reality experiences for brick-and-mortar retail stores at a digital product agency in Gothenburg."/>
            <HistoryItem
              title="UX Design Trainee at Fonk Amsterdam"
              time="Jun 2018 - Aug 2018"
              description1="Full-time summer traineeship at a digital product agency in Amsterdam. I conducted user research, and designed and prototyped interfaces. I helped a start-up define their MVP, independently completed and presented a project for an existing client, and designed microinteractions for an upcoming AR app. I also gave a Framer workshop to the UX design team."/>
            <HistoryItem
              title="UX Designer and Front-End Developer at Radboud Centre for Social Sciences"
              time="Feb 2017 — Jun 2017"
              description1="In this four month project I designed and developed a web portal. I performed user research, interaction and visual design, ran usability tests, and developed the front-end in React, together with two other developers."/>
            <HistoryItem
              title="Freelance UX Designer and Front-End Dev"
              time="Oct 2016—Jan 2017"
              description1="Designed and developed front and back-end (NodeJS) of a web app to automatically generate and analyse questionnaires."/>
            <HistoryItem
              title="Teaching Assistant in Usability / UX"
              time="2015—2017"
              description1="Taught usability, user research, and UX in B.Sc. courses at the Radboud University, and M.Sc. courses at Chalmers university. I hosted lectures and workshops, gave assignments, grading, and feedback. Re- ceived a certificate in teaching methods."/>
            </WorkCard>
            <SkillsCard>
              <H4>Skills</H4>
              <SkillsSuperlist>
                <SkillsSuperlistItem category="Design" skills={["User Interface (UI)", "User Experience (UX)", "Interation", "Product", "AR / VR"]}/>
                <SkillsSuperlistItem category="Development" skills={["HTML", "CSS", "Javascript", "React", "React Native", "NodeJS", "Angular", "Swift", "iOS"]}/>
                <SkillsSuperlistItem category="Tools" skills={["Sketch", "Framer", "Photoshop", "InDesign", "After Effects", "Cinema4D", "Unity", "Unreal Engine", "XCode"]}/>
                <SkillsSuperlistItem category="Methods" skills={["User-Centered Design", "Observations, Interviews, and Questionnaires", "Data Analysis", "Ideation Workshops", "Sketching", "Prototyping", "Usability and UX Evaluation", "Scrum & Kanban"]}/>
              </SkillsSuperlist>
            </SkillsCard>
        </Resume>
      </ResumeBackground>
      <TriviaBackground>
        <Trivia>
          <TriviaTitle>Fun Fact</TriviaTitle>
          <TriviaItem>
            <TriviaButton previous/>
            <TriviaText>My favorite typefaces are Kepler by Robert Slimbach of Adobe and Rucksack by Jeffrey Schreiber of RegularBoldItalic.</TriviaText>
            <TriviaButton next/>
          </TriviaItem>
        </Trivia>
      </TriviaBackground>
    </PageWrapper>
  </PageLayout>
)
